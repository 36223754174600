








import { Component, Vue } from 'vue-property-decorator'
import AuthLayout from '@/company/layouts/AuthLayout.vue'
import __ from '@/shared/helpers/__'
import http from '@/shared/http'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import { AuthRoutes } from '@/shared/router/auth'

@Component({
  components: { AuthLayout },
  methods: { __ }
})
export default class EmailVerification extends Vue {
  created() {
    const { id, hash } = this.$router.currentRoute.params

    http.get(`email/verify/${ id }/${ hash }`)
      .then(() => {
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.success,
          message: __('company.views.email-verification.success'),
        })

        this.$router.push({ name: AuthRoutes.login })
      })
  }
}
